<template>
  <router-link :to="{name: 'tournaments_id', params: {id: item.id}}" @click.native="goToTournament" class="tournamentCard">
    <div class="timeBlock">
      <span class="timeBlock__firstSpan">{{ getStatus }}</span>
      <span class="timeBlock__secondSpan">{{ getComputedDate }}</span>
    </div>

    <div class="nameBlock">
      <span class="nameBlock__text">{{ getName }}</span>
      <span class="nameBlock__sponsor">{{ getLocation }}</span>
    </div>

    <div class="eliminationBlock">
      <span class="eliminationBlock__text">{{ getTypeOfTournament }}</span>
    </div>

    <div class="awardBlock">
      <span class="awardBlock__text">{{ getPrizeFund }}</span>
    </div>

    <div class="countGroupBlock">
      <span class="countGroupBlock__text">{{ getCountOfParticipantsInTour }}/{{ getAllCountOfTour }}</span>
    </div>

    <div class="iconsBlock">
      <div class="iconWrapper">
        <country-flag :country="item.attributes.country" size="normal" rounded/>
      </div>
    </div>
  </router-link>
</template>

<script>
import CountryFlag from "vue-country-flag";

import parseDate from "@/otherJS/parseDate";
import {mapActions} from "vuex";


export default {
  name: "searchTournamentCard",
  props: ['item'],
  components: {CountryFlag},
  computed: {
    getStatus() {

      switch (this.item.attributes.status) {
        case 'in_process':
          return 'начался'
        default:
          return ''
      }
    },

    getComputedDate() {
      const date = new Date(this.getParseDate);
      return parseDate(date);
    },

    getParseDate() {
      switch (this.item.attributes.status) {
        case 'in_process':
          return this.item.attributes['started_at']
        default:
          return ''
      }
    },

    getName() {
      return this.item.attributes.name
    },

    getLocation() {
      return this.item.attributes.location
    },

    getTypeOfTournament() {
      if (this.item.attributes['group_type']) {
        return "Групповой турнир"
      } else {
        switch (this.item.attributes['playoff_type']) {
          case 'single_elimination':
            return 'Single Elimination'
          default:
            return ''
        }
      }
    },

    getPrizeFund() {
      return this.item.attributes['prize_fund']
    },

    getCountOfParticipantsInTour() {
      return this.item.attributes['participants_count']
    },

    getAllCountOfTour() {
      return this.item.attributes['count_of_teams']
    }
  },
  methods: {
    ...mapActions([
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN'
    ]),
    goToTournament() {
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
    }
  },
}
</script>

<style lang="scss" scoped>
.tournamentCard {
  margin-top: 8px;
  cursor: pointer;
  padding: 15px 16px;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: #3A3A3A;

  .timeBlock {
    flex: .4;
    display: flex;
    flex-direction: column;

    &__firstSpan {
      color: white;
      opacity: .6;
      font-family: 'SF UI Text', serif;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }

    &__secondSpan {
      color: white;
      font-family: 'SF UI Text', serif;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  .nameBlock {
    margin-left: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;


    &__text {
      color: white;
      font-size: 14px;
      line-height: 17px;
      font-family: 'SF UI Text', serif;
      font-weight: normal;
    }

    &__sponsor {
      margin-top: 4px;
      color: var(--tournament-color);
      font-size: 12px;
      line-height: 14px;
      font-family: 'SF UI Text', serif;
      font-weight: normal;
    }
  }

  .eliminationBlock {
    flex: .3;
    display: flex;
    flex-direction: row;
    justify-content: center;


    &__text {
      font-size: 12px;
      line-height: 14px;
      font-family: 'SF UI Text', serif;
      color: white;
    }
  }

  .awardBlock {
    flex: .3;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__text {
      font-size: 12px;
      line-height: 14px;
      font-family: 'SF UI Text', serif;
      color: white;
    }
  }

  .countGroupBlock {
    flex: .3;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__text {
      font-size: 12px;
      line-height: 14px;
      font-family: 'SF UI Text', serif;
      color: white;
    }
  }

  .iconsBlock {
    flex: .5;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .iconWrapper {

    }
  }

  &:hover {
    background-color: lighten(#3A3A3A, 10);
  }

  &:first-child {
    margin-top: 0;
  }
}

@media all and (max-width: 790px) {
  .tournamentCard {
    position: relative;
    flex-direction: column;
    align-items: flex-start;

    .nameBlock {
      margin-left: 0;
      order: -6;
    }

    .awardBlock {
      order: -5;
      margin-top: 20px;
    }

    .countGroupBlock {
      order: -4;
      margin-top: 4px;
    }

    .eliminationBlock {
      order: -3;
      margin-top: 4px;
    }

    .iconsBlock {
      display: none;
    }

    .timeBlock {
      position: absolute;
      bottom: 15px;
      right: 16px;

      text-align: right;
    }
  }
}
</style>
